/**
* Created by hansxing on 2019/3/25.
*/
<style lang="scss" scoped>
.demand-order {
    .demand-box-card {
        box-shadow: none;
        border: 0;
        border-radius: 0;
    }

    .box-btn-select {
        background: #319BF7;
        color: #fff;
        border: 1px solid #319BF7;
    }

}

.demand-order /deep/ {
    .el-form-item.is-error .el-input__inner {
        border-color: #dcdfe6;
    }

    .el-form-item__error {
        padding-top: 0;
    }

    .demand-box-card {
        margin-bottom: 10px;

        &:first-child {
            .el-card__body {
                padding-top: 0;
            }
        }

        .trip-info-com {
            .el-form-item__content {
                max-width: 660px;
            }
        }

        .el-form-item__content {
            max-width: 660px;
        }

        .el-card__body {
            padding: 10px 20px 0 20px;
        }

        .el-form-item--mini.el-form-item, .el-form-item--small.el-form-item {
            margin-bottom: 15px;

        }

        &.merchant-amount {
            .merchant-amount-list {
                .el-form-item {
                    display: inline-block;
                }
            }
        }
    }

    .order-vehicle-time {
        .el-form-item__content {
            display: flex;
        }

        .box-value_picker_time {
            width: 160px;
            display: inline-block;
            margin-left: 10px;
        }
    }

    .amount-list_v {
        .el-input {
            width: 100px;
        }

    }

}

.check-demand-info {
    .demand-info-list {
        margin-bottom: 10px;

        li {
            margin-bottom: 3px;
        }
    }

    .demand-info-title {
        /*display: inline-block;*/
        /*width: 110px;*/
        /*text-align: right;*/
        /*position: absolute;*/
        /*left: 0;*/
    }

    .demand-info-c {
        /*padding-left: 110px;*/
        /*display: inline-block;*/
    }

    li {
        min-height: 16px;
        position: relative;
        line-height: 1.2;
        text-align: justify;
    }
}

.demand-order_foot {
    text-align: right;
}


</style>
<style lang="scss">

.institutions-list-pop, .product-pop-list {
    &.el-autocomplete-suggestion li {
        padding: 0 12px;
        word-wrap: break-word;
        word-break: normal;
        white-space: inherit;

        .list-pop-bot {
            position: relative;
            padding: 0 5px;

            &:before {
                content: "";
                display: block;
                height: 1px;
                background: #f8f8f8;
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
            }
        }
    }
}

.product-pop-list {
    &.el-autocomplete-suggestion li {
        line-height: 20px;

        .list-pop-bot {
            padding: 5px
        }
    }
}

.trip-info-com {
    margin-top: 20px;
    padding: 20px;
    /*background: #F0F8FF;*/

    .trip-title {
        font-size: 14px;
        margin-bottom: 15px;
    }

    .trip-info-head {
        margin-bottom: 15px;
        font-size: 14px;

        .el-button {
            padding: 9px 20px;
            background: #E7F3FF;
            border: 1px solid #1989FA;
            color: #1890FF;
            margin-left: 5px;
        }
    }

    .trip-info-list {
        margin-bottom: 15px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.city-site-list {
    /*padding-left: 96px;*/

    li {
        line-height: 26px;
        background: #ECF6FF;
        border-radius: 3.84px;
        display: inline-block;
        float: left;
        margin-right: 10px;
        font-size: 12px;
        color: #319BF7;
        padding: 0 8px;
        /*margin-bottom: 5px;*/
        cursor: pointer;

        &.site-list-selected {
            background: #319BF7;
            color: #FFFFFF;
        }

    }
}

.quotation-order {
    .quotation-order-form {
        .city-station-item.el-form-item {
            margin-bottom: 0;
        }
    }
}

.way-address-place {
    .el-form-item {
        &:last-child {
            margin-bottom: 10px;
        }
    }

    .el-form-item__content {
        display: flex;
    }

    .delete-way-address {
        margin-left: 10px;
        height: 30px;
        width: 60px;
        padding: 0;
        margin-top: 1px;
    }
}
</style>
<template>
    <div>
        <el-main class="com-eTrip-section section-has-footer">
            <div class="demand-order eTrip-section-cont">
                <el-form ref="form" :model="form" label-width="125px" :rules="formRules" size="small">
                    <el-card class="demand-box-card">
                        <el-form-item label="类型：">
                            <el-button :class="{'box-btn-select':item.value == form.productType}"
                                       v-for="(item, index) in demandType" :key="index"
                                       @click="handleDemType(item.value)">{{ item.label }}
                            </el-button>
                        </el-form-item>
                        <el-form-item label="下单机构：" prop="orderInst">
                            <el-autocomplete
                                style="width: 100%"
                                popper-class="institutions-list-pop"
                                v-model="form.mechanism"
                                :fetch-suggestions="getMechanismList"
                                :trigger-on-focus="false"
                                placeholder="请输入下单机构名称搜素"
                                @select="handMechanismList"
                                clearable>
                                <template slot-scope="{ item }">
                                    <div class="list-pop-bot">
                                        <span>{{ '[' + item.orgId + ']' }}</span>
                                        <span>{{ item.merchantName }}</span>
                                    </div>
                                </template>
                            </el-autocomplete>
                        </el-form-item>
                        <el-form-item label="第三方订单号：" prop="orderOrginfoId" v-if="isGivenShow">
                            <el-input v-model="form.orderOrginfoId" placeholder="请输入第三方订单号"
                                      clearable></el-input>
                        </el-form-item>
                    </el-card>
                    <el-card class="demand-box-card">
                        <div v-if="form.productType == 3">
                            <el-form-item label="是否现有资源：">
                                <el-radio-group v-model="form.resourceExist" @change="clearResource">
                                    <el-radio-button label="是"></el-radio-button>
                                    <el-radio-button label="否"></el-radio-button>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item v-if="form.resourceExist==='是'" label="资源：" prop="resourcesInst">
                                <el-autocomplete
                                    style="width: 100%"
                                    popper-class="product-pop-list"
                                    :value="form.resourceName"
                                    v-model="form.resourceName"
                                    :fetch-suggestions="getProductResName"
                                    :trigger-on-focus="false"
                                    placeholder="请输入资源名称搜素"
                                    @select="handProductResList"
                                    @clear="clearResource"
                                    clearable>
                                    <template slot-scope="{ item }">
                                        <div class="list-pop-bot">
                                            <div>
                                                {{ '[' + item.productNo + ']' }}
                                                <span>{{ item.productName }}</span>
                                            </div>
                                            <div style="padding-left: 20px">
                                                {{ '[' + item.resourceNo + ']' }}
                                                <span>{{ item.resourceName }}</span>
                                            </div>
                                        </div>
                                    </template>
                                </el-autocomplete>
                            </el-form-item>
                            <div v-else>
                                <el-form-item label="是否用车：" v-if="form.productType!==18">
                                    <el-radio-group v-model="form.useCar">
                                        <el-radio-button label="用车"></el-radio-button>
                                        <el-radio-button label="非用车"></el-radio-button>
                                    </el-radio-group>
                                </el-form-item>
                                <el-form-item label="产品：" prop="productName">
                                    <el-input placeholder="请输入产品名称" v-model="form.productName"></el-input>
                                </el-form-item>
                            </div>
                        </div>
                        <div v-if="form.productType != 3||useCar">
                            <el-form-item label="出发地点：" prop="departure"
                                          v-if="form.productType == 1||useCar">
                                <search-address
                                    isSlot
                                    :address="form.departure"
                                    input-width="660px"
                                    placeholder="请输入出发地点"
                                    @update:searchAddress="handStartPlace"></search-address>
                                <ul class="city-site-list clear">
                                    <li v-for="(s,index) in stationList"
                                        :class="{'site-list-selected': citySiteOnId == s.id && form.departure.address}"
                                        :style="{'margin-top':(s.stationName ? '15px':'')}"
                                        :key="index"
                                        @click="handleCitySite(s, 1)">{{ s.stationName }}
                                    </li>
                                </ul>
                            </el-form-item>
                            <el-form-item label="送达地点：" prop="destination" style=""
                                          v-if="form.productType == 1||useCar">
                                <search-address
                                    isSlot
                                    :address="form.destination"
                                    input-width="660px"
                                    placeholder="请输入送达地点"
                                    @update:searchAddress="handEndPlace"
                                ></search-address>
                                <ul class="city-site-list clear">
                                    <li v-for="(s,index) in stationList1"
                                        :class="{'site-list-selected': citySiteOffId == s.id && form.destination.address}"
                                        :style="{'margin-top':(s.stationName ? '15px':'')}"
                                        :key="index"
                                        @click="handleCitySite(s, 2)">{{ s.stationName }}
                                    </li>
                                </ul>

                            </el-form-item>
                            <el-form-item label="乘车方式：" v-if="form.productType == 1">
                                <el-button :class="{'box-btn-select':item.value == form.resourcePriceWay}"
                                           v-for="(item, index) in vehicleTypeList" :key="index"
                                           @click="handVehicle(item.value)">{{ item.label }}
                                </el-button>
                            </el-form-item>
                            <el-form-item label="出发城市：" class="start-play-city" v-if="form.productType == 2">
                                <select-city :city="form.productStartCityName"
                                             :is-border="false"
                                             :default-city="$store.state.location.cityName"
                                             @update:getCity="selectDestCityName"
                                             @onUpdate:isFocus="hideCitySel">
                                </select-city>
                            </el-form-item>
                            <el-form-item label="车座车型：">
                                <seat-model v-model="form.vehicleType" @selectSeat="handSaddleTypeChange"></seat-model>
                            </el-form-item>
                        </div>
                        <el-form-item :label="theCarDateTitle" class="order-vehicle-time" prop="theCarDate">
                            <el-date-picker value-format="yyyy/MM/dd"
                                            type="date"
                                            v-model="form.theCarDate"
                                            placeholder="选择日期"
                                            style="width: 180px"
                                            :picker-options="carDatepickerOptions"
                                            @change="handSelectDate">
                            </el-date-picker>
                            <time-picker-with-title height-box="30" v-if="form.productType == 1||useCar"
                                                    is-title
                                                    placeholder="选择用车时间"
                                                    :default-hour="theCarHour"
                                                    :default-minute="theCarMinutes"
                                                    :timeMinuteStep="timeMinute"
                                                    @onUpdate="handSelectTime"
                            ></time-picker-with-title>
                        </el-form-item>
                        <el-form-item label="用车天数：" v-if="form.productType == 2||useCar">
                            <el-select v-model="form.tripDay" placeholder="请选择" style="width: 70px"
                                       @change="handTripDayChange(form.tripDay)">
                                <el-option
                                    v-for="(item, index) in tripDaysOp" :key="index"
                                    :label="item"
                                    :value="item">
                                </el-option>
                            </el-select>
                            <span class="ml10">天</span>
                        </el-form-item>
                        <el-form-item label="包车类型：" prop="charteredCarDuration"
                                      v-if="form.productType == 2">
                            <ApiSelect placeholder="请选择包车类型" filterable clearable
                                       :options="typeOfCharterCarOptions"
                                       v-model="form.charteredCarDuration"/>
                        </el-form-item>
                        <el-form-item label="出行人数：">
                            <el-input-number v-model="form.playPersonNumber" @change="handPeopleNumChange" :min="1"
                                             :max="maxPeopleNum"
                                             label="描述文字"></el-input-number>
                            <span class="ml10">人</span>
                        </el-form-item>
                        <el-form-item label="出行人姓名：" style="width: 360px" prop="tripUserName">
                            <el-input v-model="form.tripUserName" :maxlength="50"
                                      placeholder="请输入出行人姓名"></el-input>
                        </el-form-item>
                        <el-form-item label="出行人手机号：" style="width: 360px" prop="tripUserMobile">
                            <el-input v-model="form.tripUserMobile" maxlength="11"
                                      placeholder="请输入出行人手机号"></el-input>
                        </el-form-item>
                        <el-form-item label="备注：">
                            <el-input class="order-remark"
                                      type="textarea"
                                      :rows="5"
                                      placeholder="请输入备注信息（选填）"
                                      v-model="form.orderRemake">
                            </el-input>
                        </el-form-item>
                        <div class="trip-info-com" v-if="form.productType == 2">
                            <div class="trip-title">行程信息：(选填，完整填写有助于客服准确报价)</div>
                            <div class="trip-info-list" v-for="(item, index) in form.itineraryInfo" :key="index">
                                <div class="trip-info-head">
                                    <span>第{{ item.travelDay }}天（{{ item.date | dateCus('yyyy-MM-dd') }}）</span>
                                    <el-button @click="addWayAddress(index)">添加途径地址</el-button>
                                </div>
                                <div>
                                    <el-form-item label="用车时间：" label-width="96px">
                                        <time-picker-with-title
                                            style="width: 120px;"
                                            isTitle
                                            heightBox="32"
                                            :index-ident="{index}"
                                            title="用车时间"
                                            placeholder="选择用车时间"
                                            :defaultHour="item.handTripTimeHour"
                                            :defaultMinute="item.handTripTimeMinute"
                                            :timeMinuteStep="newTimeSel.timeMinuteStep"
                                            @onUpdate="handTripTime"
                                        ></time-picker-with-title>
                                    </el-form-item>
                                    <el-form-item label="出发地址：" label-width="96px"
                                                  :prop="'itineraryInfo[' + index + '].tripStartPlace.value'"
                                                  :rules="{required: false, message: '请选择出发地址', trigger: 'change'}">
                                        <search-address
                                            isSlot
                                            :index-ident="{index}"
                                            :address="item.tripStartPlace"
                                            input-width="600px"
                                            placeholder="请输入出发地址"
                                            @update:searchAddress="handTripStartPlace"
                                        ></search-address>
                                    </el-form-item>
                                    <div v-for="(list, idxAddress) in item.wayAddressList" :key="list.id"
                                         style="margin-bottom: 15px;">
                                        <el-form-item label="途径地址：" label-width="96px" class="way-address-place"
                                                      :prop="'itineraryInfo[' + index + '].wayAddressList[' + idxAddress +'].tripWayPlace.value'"
                                                      :rules="{required: true, message: '请选择途径地址', trigger: 'change'}"
                                        >
                                            <search-address
                                                isSlot
                                                :index-ident="{index,idxAddress}"
                                                :address="list.tripWayPlace"
                                                input-width="600px"
                                                placeholder="请输入途径地址"
                                                @update:searchAddress="handWayAddressPlace"
                                            ></search-address>
                                            <el-button @click="deleteWayAddress(index, idxAddress)"
                                                       class="delete-way-address"
                                                       type="danger" plain>删除
                                            </el-button>
                                        </el-form-item>
                                    </div>


                                    <el-form-item label="送达地址：" label-width="96px"
                                                  :prop="'itineraryInfo[' + index + '].tripEndPlace.value'"
                                                  :rules="{required: false, message: '请选择送达地址', trigger: 'change'}">
                                        <search-address
                                            isSlot
                                            input-width="600px"
                                            :index-ident="{index}"
                                            :address="item.tripEndPlace"
                                            placeholder="请输入送达地址"
                                            @update:searchAddress="handTripEndPlace"
                                        ></search-address>
                                    </el-form-item>
                                </div>

                            </div>
                        </div>
                    </el-card>
                    <el-card class="demand-box-card merchant-amount" v-if="form.productType == 3">
                        <div class="merchant-amount-list">
                            <el-form-item :label="useCar?'导游姓名：':'司导姓名:'" prop="guiderName"
                                          :rules="[{ required:form.resourceExist === '是'||!useCar,message:(useCar?'导游姓名':'司导姓名')+'不能为空'},...formRules.guiderName]">
                                <el-input v-model="form.guiderName"
                                          :placeholder="useCar?'请输入导游姓名':'请输入司导姓名'"
                                          :maxlength="10" clearable></el-input>
                            </el-form-item>
                        </div>
                    </el-card>
                    <el-card class="demand-box-card merchant-amount" v-if="form.productType == 3&&useCar">
                        <div class="merchant-amount-list">
                            <el-form-item label="司机姓名：" prop="distributionGuiderDto.guiderName">
                                <el-autocomplete
                                    v-model="form.distributionGuiderDto.guiderName"
                                    :fetch-suggestions="queryDriverByName" placeholder="请输入司机姓名"
                                    :maxlength="10" clearable
                                    @blur="handleClearDriverId('guiderName')"
                                    @clear="handleClearDriverId('guiderName')"
                                    @select="handleSelectDriver"></el-autocomplete>
                            </el-form-item>
                            <el-form-item label="司机手机：" prop="distributionGuiderDto.guiderMobile">
                                <el-autocomplete
                                    v-model="form.distributionGuiderDto.guiderMobile"
                                    :fetch-suggestions="queryDriverByMobile" placeholder="请输入司机手机"
                                    type="tel" :maxlength="11" clearable style="width: 168px"
                                    @blur="handleClearDriverId('guiderMobile')"
                                    @clear="handleClearDriverId('guiderMobile')"
                                    @select="handleSelectDriver"></el-autocomplete>
                            </el-form-item>
                            <el-form-item label="车牌号：" prop="distributionGuiderDto.carNumber">
                                <el-input v-model="form.distributionGuiderDto.carNum" :maxlength="7" minlength="5"
                                          placeholder="5-7位数字、字母组成">
                                    <el-select v-model="form.distributionGuiderDto.selectShortCity" slot="prepend"
                                               clearable
                                               style="width: 100px">
                                        <el-option v-for="pc in plateCity"
                                                   :key="pc.value"
                                                   :label="pc.value"
                                                   :value="pc.value">
                                        </el-option>
                                    </el-select>
                                </el-input>
                            </el-form-item>
                            <el-form-item label="车型：" prop="distributionGuiderDto.carType">
                                <seat-model :isAll="false"
                                            v-model="form.distributionGuiderDto.carType" clearable></seat-model>
                            </el-form-item>
                            <el-form-item label="车辆品牌：">
                                <el-input v-model="form.distributionGuiderDto.carBrand"
                                          placeholder="请输入车辆品牌" style="width: 168px" clearable></el-input>
                            </el-form-item>
                            <el-form-item label="车辆型号：">
                                <el-input v-model="form.distributionGuiderDto.carModel"
                                          placeholder="请输入车辆型号" clearable style="width: 270px"></el-input>
                            </el-form-item>
                            <el-form-item label="品牌型号：">
                                <ApiCascader :api="getCarBrandModelList"
                                             v-model="form.distributionGuiderDto.carBrandModel"
                                             placeholder="品牌型号" clearable filterable></ApiCascader>
                            </el-form-item>
                            <el-form-item label="车辆颜色：">
                                <el-input v-model="form.distributionGuiderDto.carColor" :maxlength="5"
                                          placeholder="颜色" clearable style="width: 168px"></el-input>
                            </el-form-item>
                            <el-form-item label="司机到手价：">
                                <el-input prefix-icon="icon icon-daifukuan"
                                          v-model="form.distributionGuiderDto.driverPrice"
                                          placeholder="请输入司机到手价"
                                          clearable
                                          style="width: 270px"
                                          @change="clearAmountNum(form.distributionGuiderDto.driverPrice, 'distributionGuiderDto.driverPrice', 2)"></el-input>
                            </el-form-item>
                        </div>
                    </el-card>
                    <el-card class="demand-box-card merchant-amount">
                        <div class="merchant-amount-list">
                            <el-form-item label="运营商：" prop="operatorValue">
                                <el-select v-model="form.operatorValue"
                                           placeholder="请选择运营商"
                                           style="width: 260px"
                                           :disabled="operatorDisabled"
                                           :clearable="true"
                                           :filterable="true"
                                           @change="handOperatorChange">
                                    <el-option
                                        v-for="item in operatorOptions"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="订单金额："
                                          class="amount-list_v"
                                          label-width="110px"
                                          prop="orderAmount">
                                <el-input v-model="form.orderAmount" clearable style="width: 140px"
                                          placeholder="请输入金额"
                                          @change="clearAmountNum(form.orderAmount, 'orderAmount', 2)">
                                </el-input>
                                <span class="ml10">元</span>
                            </el-form-item>
                            <el-form-item label="应收金额："
                                          v-if="form.productType===3"
                                          class="amount-list_v"
                                          label-width="110px"
                                          prop="receivableAmount">
                                <el-input style="width: 120px" clearable v-model="form.receivableAmount"
                                          placeholder="请输入金额"
                                          @change="clearAmountNum(form.receivableAmount, 'receivableAmount', 2)">
                                </el-input>
                                <span class="ml10">元</span>
                            </el-form-item>
                            <el-form-item label="渠道费：" label-width="90px" v-if="form.receivableAmount">
                                <span :class="form.orderAmount - form.receivableAmount<0?'color-error':''">
                                        <span>{{ ((form.orderAmount - form.receivableAmount) || 0).toFixed(2) }}</span>
                                <span class="ml10">元</span>
                                </span>
                            </el-form-item>
                        </div>
                        <div class="merchant-amount-list">
                            <el-form-item label="供应商：" prop="supplierValue"
                                          :rules="[{required:form.productType==3&&form.resourceExist==='否',message:'请选择供应商'}]">
                                <el-select v-model="form.supplierValue"
                                           placeholder="请选择供应商（为空则不填）"
                                           :disabled="supplierDisabled"
                                           :clearable="true"
                                           :filterable="true"
                                           style="width: 260px"
                                           @change="handSupplierChange">
                                    <el-option
                                        v-for="item in supplierOptions"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="结算金额：" class="amount-list_v" label-width="110px">
                                <el-input style="width: 140px" clearable v-model="form.setAmount"
                                          placeholder="请输入金额"
                                          @change="clearAmountNum(form.setAmount, 'setAmount', 2)"></el-input>
                                <span class="ml10">元</span>
                            </el-form-item>
                        </div>
                    </el-card>
                </el-form>
                <el-dialog title="确认如下订单信息"
                           width="420px"
                           :close-on-click-modal="false"
                           :show-close="false"
                           custom-class="el-dialog-middle-center"
                           class="mod-driver-confirm"
                           :visible.sync="demandInfoShow">
                    <div class="check-demand-info">
                        <ul class="demand-info-list">
                            <li>
                                <span class="demand-info-title">类型：</span>
                                <span class="demand-info-c">{{ form.productType | demandTypeDesc }}</span>
                            </li>
                            <li>
                                <span class="demand-info-title">下单渠道：</span>
                                <span class="demand-info-c">{{ form.mechanism }}</span>
                            </li>
                            <li v-if="form.orderOrginfoId">
                                <span class="demand-info-title">第三方订单号：</span>
                                <span class="demand-info-c">{{ form.orderOrginfoId }}</span>
                            </li>
                        </ul>
                        <ul class="demand-info-list">
                            <li v-if="form.productType == 3&&(form.resourcesInst||form.productName)">
                                <span class="demand-info-title">产品：</span>
                                <span class="demand-info-c" v-if="form.resourcesInst">
                                    {{ '[' + form.resourcesInst.productNo + ']' }} {{
                                        form.resourcesInst.productName
                                    }}</span>
                                <span class="demand-info-c" v-else>
                                    {{ form.productName }}
                                </span>
                            </li>
                            <li v-if="form.productType == 3 && form.resourcesInst">
                                <span class="demand-info-title">资源：</span>
                                <span class="demand-info-c">
                                    {{ '[' + form.resourcesInst.resourceNo + ']' }} {{
                                        form.resourcesInst.resourceName
                                    }}</span>
                            </li>
                            <li v-if="[1,2].includes(form.productType)||(form.productType==3&&useCar)">
                                <span class="demand-info-title">出发地点：</span>
                                <span class="demand-info-c">{{
                                        form.departure.address || '具体地址联系客人确认'
                                    }}</span>
                            </li>
                            <li v-if="[1,2].includes(form.productType)||(form.productType==3&&useCar)">
                                <span class="demand-info-title">送达地点：</span>
                                <span class="demand-info-c">{{
                                        form.destination.address || '具体地址联系客人确认'
                                    }}</span>
                            </li>
                            <li v-if="form.productType == 1">
                                <span class="demand-info-title">乘车方式：</span>
                                <span class="demand-info-c">{{ form.resourcePriceWay | resourcePriceWayDesc }}</span>
                            </li>
                            <li v-if="[1,2].includes(form.productType)||(form.productType==3&&useCar)">
                                <span class="demand-info-title">车座车型：</span>
                                <span class="demand-info-c">{{ seatModelInfo.name }}</span>
                            </li>
                            <li>
                                <span class="demand-info-title">用车时间：</span>
                                <span
                                    class="demand-info-c">{{
                                        form.theCarDate | dateCus('yyyy-MM-dd')
                                    }} {{
                                        form.productType == 3 && useCar ? `${form.theCarTime || '08:00'}` : form.theCarTime
                                    }}</span>
                            </li>
                            <li v-if="[2].includes(form.productType)||(form.productType==3&&useCar)">
                                <span class="demand-info-title">用车天数：</span>
                                <span class="demand-info-c">{{ form.tripDay }}</span>
                            </li>
                            <li v-if="[2].includes(form.productType)">
                                <span class="demand-info-title">包车类型：</span>
                                <span
                                    class="demand-info-c">{{
                                        form.charteredCarDuration | formatDesc(typeOfCharterCarOptions)
                                    }}</span>
                            </li>
                            <li>
                                <span class="demand-info-title">出行人数：</span>
                                <span class="demand-info-c">{{ form.playPersonNumber }}</span>
                            </li>
                            <li>
                                <span class="demand-info-title">出行人姓名：</span>
                                <span class="demand-info-c">{{ form.tripUserName }}</span>
                            </li>
                            <li>
                                <span class="demand-info-title">出行人手机号：</span>
                                <span class="demand-info-c">{{ form.tripUserMobile }}</span>
                            </li>
                            <li v-if="form.orderRemake">
                                <span class="demand-info-title">备注：</span>
                                <span class="demand-info-c">{{ form.orderRemake }}</span>
                            </li>
                        </ul>
                        <ul class="demand-info-list" v-if="form.productType == 3&&form.guiderName">
                            <li>
                                <span class="demand-info-title">导游姓名：</span>
                                <span class="demand-info-c">{{ form.guiderName }}</span>
                            </li>
                        </ul>
                        <ul class="demand-info-list" v-if="form.distributionGuiderDto.guiderName">
                            <li>
                                <span class="demand-info-title">司机姓名：</span>
                                <span class="demand-info-c">{{ form.distributionGuiderDto.guiderName }}</span>
                            </li>
                            <li>
                                <span class="demand-info-title"
                                      v-if="form.distributionGuiderDto.guiderMobile">司机手机：</span>
                                <span class="demand-info-c">{{ form.distributionGuiderDto.guiderMobile }}</span>
                            </li>
                            <li>
                                <span class="demand-info-title">车牌号：</span>
                                <span class="demand-info-c">{{ form.distributionGuiderDto.carNumber }}</span>
                            </li>
                            <li>
                                <span class="demand-info-title">车型：</span>
                                <span class="demand-info-c">{{
                                        form.distributionGuiderDto.carType | seatModelDesc
                                    }}</span>
                            </li>
                            <li v-if="form.distributionGuiderDto.carBrand">
                                <span class="demand-info-title">车辆品牌：</span>
                                <span class="demand-info-c">{{ form.distributionGuiderDto.carBrand }}</span>
                            </li>
                            <li v-if="form.distributionGuiderDto.carModel">
                                <span class="demand-info-title">车辆型号：</span>
                                <span class="demand-info-c">{{ form.distributionGuiderDto.carModel }}</span>
                            </li>
                            <li v-if="form.distributionGuiderDto.carBrandModel">
                                <span class="demand-info-title">品牌型号：</span>
                                <span class="demand-info-c">{{
                                        form.distributionGuiderDto.carBrandModel.join('/')
                                    }}</span>
                            </li>
                            <li v-if="form.distributionGuiderDto.carColor">
                                <span class="demand-info-title">车辆颜色：</span>
                                <span class="demand-info-c">{{ form.distributionGuiderDto.carColor }}</span>
                            </li>
                            <li v-if="form.distributionGuiderDto.driverPrice">
                                <span class="demand-info-title">司机到手价：</span>
                                <span class="demand-info-c">{{ form.distributionGuiderDto.driverPrice }}</span>
                            </li>
                        </ul>
                        <ul class="demand-info-list">
                            <li>
                                <span class="demand-info-title">运营商：</span>
                                <span class="demand-info-c"
                                      v-if="form.operatorInfo">{{ form.operatorInfo.operatorName }}</span>
                            </li>
                            <li>
                                <span class="demand-info-title">订单金额：</span>
                                <span class="demand-info-c">{{ form.orderAmount }}</span>
                            </li>
                            <li>
                                <span class="demand-info-title">应收金额：</span>
                                <span class="demand-info-c">{{ form.receivableAmount }}</span>
                            </li>
                            <li>
                                <span class="demand-info-title">供应商：</span>
                                <span class="demand-info-c"
                                      v-if="form.supplierInfo">{{ form.supplierInfo.supplierName }}</span>
                                <span class="demand-info-c" v-else>待定</span>
                            </li>
                            <li>
                                <span class="demand-info-title">结算金额：</span>
                                <span class="demand-info-c">{{ form.setAmount || '0.00' }}</span>
                            </li>
                        </ul>
                    </div>
                    <div slot="footer" class="dialog-footer">
                        <el-button @click="cancelDemandOrder">取消</el-button>
                        <!--                        <el-button type="primary" @click="getDemandOrder">确定</el-button>-->
                        <el-button type="primary" :disabled="subLoading" v-debounce="getDemandOrder">确定</el-button>
                    </div>
                </el-dialog>
            </div>
        </el-main>
        <el-footer class="com-eTrip-footer" height="64px">
            <div class="eTrip-footer-cont demand-order_foot">
                <ImportOrder v-if="form.productType===1">
                    <el-button class="mr10" type="primary" plain>导入订单</el-button>
                </ImportOrder>
                <el-button type="primary" @click="handBookBtn">预订</el-button>
            </div>
        </el-footer>

    </div>

</template>

<script type="text/ecmascript-6">
import {isCellphone, uniqueArray} from '@/common/js/Utils';
import {findItem} from '@/common/js/common'
import config from '@/config';
import searchAddress from '@/components/searchAddress/index.vue'
import SeatModel from "@/components/seatModel/index";
import {getSeatModel, typeOfCharterCarOptions} from "@/data/index";
import {keepTwoDecimal} from "@/common/js/Utils";
import {getCityData} from '@/urls/index';
import selectCity from '@/components/selectCity/'
import timePickerWithTitle from '@/components/timePickerWithTitle/index.vue'
import plateCity from '@/common/js/data/plateCity.json';
import {getCarBrandModelListApi} from '@/urls/index'
import ImportOrder from "@/www/pages/orderManage/demandOrder/ImportOrder";
import dayjs from "dayjs";

const demandType = [{
    value: 1,
    label: '接送服务'
}, {
    value: 2,
    label: '按日包车'
}, {
    value: 3,
    label: '旅游线路'
}];
const vehicleTypeList = [{
    value: 0,
    label: '专车'
}, {
    value: 1,
    label: '拼车'
}];
let fat = [
    '8ac2387769c7ffca0169c801ff6a0009',//携程
    '8ac2387769c7ffca0169c80215e70010',//飞猪
    '8ac2387769c7ffca0169c80225440017',//马蜂窝
    '8ac2387769c7ffca0169c8023375001e',//去哪儿
]
let production = [
    '8a9e34a169e59a10016a0139d27d0039',//携程
    '8a9e34a169e59a10016a0139e5450040',//飞猪
    '8a9e34a169e59a10016a0139f40a0047',//马蜂窝
    '8a9fadc469e2c221016a013a07dd0036',//去哪儿
    '8a9e34a1730ef6000173233c9ede0243',
    '8a9e34a1844fbece0184894c56ed54a6'
]
const oneDayTime = 86400000;

const initForm = {
    mechanism: null, // 下单机构
    resourceExist: '是',
    useCar: '用车',
    resourceName: null,  // 资源
    productType: 1,  // 默认类型
    orderInst: null,  // 校验下单机构
    resourcesInst: null,  // 校验资源
    orderOrginfoId: '',  // 第三方订单号
    // orderIdItem:[],
    vehicleType: 1,  // 车座车型
    playPersonNumber: 1,  // 出行人数
    tripDay: 1,  // 用车天数
    resourcePriceWay: 0,  // 乘车方式 0 专车 1 拼车
    departure: {
        city: "上海市",
        title: ""
    },  // 出发地点
    destination: {
        city: "上海市",
        title: ""
    }, // 送达地点
    theCarDate: dayjs().add(1, 'd').format('YYYY/MM/DD'),
    theCarTime: '08:00',
    operatorValue: '',  // 运营商
    guiderName: '', // 导游姓名
    orderAmount: '',  // 订单金额
    operatorInfo: null,  // 当前选中运营商
    supplierValue: '',  // 供应商
    setAmount: '',  // 结算金额
    supplierInfo: null,  // 当前选中供应商
    itineraryInfo: [],
    productStartCityName: undefined,  // 用车城市
    distributionGuiderDto: {
        guiderName: '',
        guiderMobile: '',
        carNumber: '',
        carColor: '',
        driverPrice: undefined,
        carBrand: "",
        carModel: "",
        carType: null,
        carOwner: "",
        idCardNumber: "",
        sourceType: 11
    },
    receivableAmount: null,
    charteredCarDuration: undefined
}
export default {
    name: 'demand',
    //定义模版数据
    data() {
        let timeMinute = ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55'];
        let userInfo = this.$store.state.loginUserInfo;
        return {
            typeOfCharterCarOptions,
            getCarBrandModelList: async () => {
                const res = await getCarBrandModelListApi();
                if (res.success) {
                    let brandModelLsit = uniqueArray(res.data, 'carBrand');
                    let carList = [];
                    brandModelLsit.forEach(item => {
                        let list = {
                            value: item.carBrand,
                            label: item.carBrand,
                            children: []
                        }
                        res.data.forEach(item1 => {
                            if (item.carBrand == item1.carBrand) {
                                let data = {
                                    value: item1.carModel,
                                    label: item1.carModel,
                                    carType: item1.carType
                                }
                                list.children.push(data);
                            }

                        })
                        carList.push(list);
                    })
                    res.data = carList;
                }
                return res
            },
            userInfo: userInfo,
            oneDayTime: oneDayTime,
            plateCity,
            newTimeSel: {
                timeMinuteStep: ['00', '10', '20', '30', '40', '50'],
            },
            theCarDateTitle: '用车时间：',
            demandType,  // 下单需求类型("产品类型"1:接送服务2:按日包车3:旅游线路)productType;
            vehicleTypeList, // 乘车方式 1 专车 2 拼车
            timeMinute,
            demandInfoShow: false,
            theCarHour: '08',
            theCarMinutes: '00',
            maxPeopleNum: 4,  // 最大人数
            // tripDayStep: 0.5,  // 天数初始化步长+
            // tripDayPrecision: 1,  //天数数值精度
            operatorOptions: null,  // 运营商
            operatorDisabled: false,  // 运营商是否禁用
            supplierOptions: null,  // 供应商
            supplierDisabled: false,  // 供应商是否禁用
            form: {...initForm},
            serverCityList: [],
            stationList: [],
            stationList1: [],
            citySiteOnId: '',
            citySiteOffId: '',
            showHideCity: false,
            subLoading: false,
            formRules: {
                charteredCarDuration: [{required: true, message: '请选择包车类型'}],
                orderInst: [{
                    validator: async (rule, value, callback) => {
                        if (!value)
                            callback("下单机构不能为空");
                        else
                            callback()
                    }, required: true, trigger: 'blur'
                }],
                orderOrginfoId: [{
                    validator: async (rule, value, callback) => {
                        if (!value)
                            callback("第三方订单号不能为空");
                        else
                            callback()
                    }, required: true, trigger: 'blur'
                }],
                resourcesInst: [{
                    validator: async (rule, value, callback) => {
                        if (!value)
                            callback("资源不能为空");
                        else
                            callback()
                    }, required: true, trigger: 'blur'
                }],
                departure: [{
                    validator: async (rule, value, callback) => {
                        if (!value)
                            callback("出发地点不能为空");
                        else {
                            if (!this.form.departure.value)
                                callback("请选择正确的出发地点");
                            else callback()
                        }
                    }, required: true, trigger: 'blur'
                }],
                destination: [{
                    validator: async (rule, value, callback) => {
                        if (!value)
                            callback("送达地点不能为空");
                        else {
                            if (!this.form.destination.value)
                                callback("请选择正确的送达地点");
                            else callback()
                        }
                    }, required: true, trigger: 'blur'
                }],
                theCarDate: [{required: true, message: '用车时间不能为空', trigger: 'blur'}],
                tripUserName: [{
                    validator: async (rule, value, callback) => {
                        if (!value)
                            callback("出行人姓名不能为空");
                        else callback()
                    }, required: true, trigger: 'blur'
                }],
                tripUserMobile: [{
                    validator: async (rule, value, callback) => {
                        if (!value)
                            callback("出行人手机号不能为空");
                        else {
                            if (!isCellphone(this.form.tripUserMobile))
                                callback("出行人手机号格式错误");
                            else callback()
                        }
                    }, required: true, trigger: 'blur'
                }],
                guiderName: [{
                    validator: async (rule, value, callback) => {
                        if (this.form.resourceExist === '是' || !this.useCar) {
                            if (!value)
                                return callback("导游姓名不能为空");
                        } else {
                            if (!value && !this.form.distributionGuiderDto.guiderName)
                                return callback('导游和司机必须填写一个')
                        }
                        return callback()
                    },
                    trigger: 'blur'
                }],
                operatorValue: [{required: true, message: '请选择运营商', trigger: 'blur'}],
                orderAmount: [
                    {
                        required: true,
                        validator: (rule, value, callback) => {
                            const num = value ? Number(value) : undefined;
                            if (!num && num !== 0)
                                return callback('请填写订单金额')
                            if (num <= 0 && this.form.resourceExist === '否' && this.form.productType === 3)
                                return callback('订单金额必须大于0')
                            callback();
                        },
                        trigger: 'blur'
                    },
                ],
                receivableAmount: [{required: true, message: '请填写应收金额', trigger: 'blur'}],
                "distributionGuiderDto.guiderName": [{
                    validator: (rule, value, callback) => {
                        if (!this.form.guiderName && !value)
                            return callback('导游和司机必须填写一个')
                        if ((this.form.distributionGuiderDto.carType || this.form.distributionGuiderDto.carNumber) && !value) {
                            return callback('请输入司机姓名')
                        }
                        return callback();
                    },
                    trigger: 'blur'
                }],
                "distributionGuiderDto.carNumber": [{
                    validator: (rule, value, callback) => {
                        if ((this.form.distributionGuiderDto.carType || this.form.distributionGuiderDto.guiderName) && !value) {
                            return callback('请输入车牌号')
                        }
                        if (value && !this.form.distributionGuiderDto.selectShortCity)
                            return callback('请选择城市')
                        if (value && !this.form.distributionGuiderDto.carNum)
                            return callback('请输入车牌号')
                        return callback();
                    },
                    trigger: 'change'
                }],
                "distributionGuiderDto.carType": [{
                    validator: (rule, value, callback) => {
                        if ((this.form.distributionGuiderDto.guiderName || this.form.distributionGuiderDto.carNumber) && !value) {
                            return callback('请选择车型')
                        }
                        return callback();
                    },
                    trigger: 'blur'
                }]
            },
            seatModelInfo: {},
            carDatepickerOptions: {
                disabledDate(time) {
                    const now = dayjs();
                    const date = dayjs(time);
                    const newDay = now.date();
                    if (date.isSame(now.subtract(2, 'M'), 'M'))
                        return true;
                    if (newDay > 3 && date.isSame(now.subtract(1, 'M'), 'M'))
                        return true
                    return false;
                },
            }
        }
    },
    components: {
        timePickerWithTitle,
        searchAddress,
        SeatModel,
        selectCity,
        ImportOrder
    },
    filters: {
        demandTypeDesc(value) {
            return findItem(demandType, value)
        },
        resourcePriceWayDesc(value) {
            return findItem(vehicleTypeList, value)
        }
    },
    //计算属性
    computed: {
        orderInfo: function () {
            return this.$route.query.orderInfo
        },
        tripDaysOp: function () {
            let tripDayItem = [];
            for (let i = 1; i <= 20; i++) {
                tripDayItem.push(i)
            }
            return tripDayItem
        },
        isGivenShow() {
            let ids = config.Debug ? fat.join(',') : production.join(',');
            const orderInst = this.form.orderInst || {};
            return ids.indexOf(orderInst.id) !== -1
        },
        useCar() {
            return this.form.resourceExist === '否' && this.form.useCar === '用车'
        }
    },
    //主件被加载完成
    mounted: function () {
        this.init();
    },
    //定义事件方法
    methods: {
        init() {
            this.form = JSON.parse(JSON.stringify(initForm))
            this.getServerCity();
            this.getOperator();
            if (this.userInfo.userType == '2')
                this.getUserType();
            if (this.userInfo.userType == '1') {
                this.getSupplierId(this.userInfo.refId)
                this.demandType = [{
                    value: 1,
                    label: '接送服务'
                }, {
                    value: 2,
                    label: '按日包车'
                }]
            }
            if (this.orderInfo) {
                this.getInitOrderInfo(JSON.parse(decodeURI(this.orderInfo)))
            }
            this.form.productStartCityName = this.$store.state.location.cityName
        },
        async getServerCity() {
            const ret = await getCityData(this, '2');
            if (ret)
                this.serverCityList = ret;
        },
        async getStation(id, v) {
            let form = {
                shortCode: id,
                // stationType: 2
            }
            let res = await this.http('/galaxyStationApi/doStationList', form, 'POST');
            if (res.success) {
                switch (v) {
                    case 1:
                        this.stationList = res.data;
                        break;
                    case 2:
                        this.stationList1 = res.data;
                        break
                }
            }
        },
        handleCitySite(v, i) {
            switch (i) {
                case 1:
                    this.$refs["form"].clearValidate("departure");
                    // this.searchForm.stationId = v.id;
                    this.citySiteOnId = v.id;
                    this.form.departure = v;
                    if (v) {
                        this.form.departure = {
                            address: v.stationName,
                            city: v.cityName,
                            location: v.longitude + ',' + v.latitude,
                            title: v.stationName,
                            value: v.stationName,
                        }
                    }
                    break;
                case 2:
                    this.$refs["form"].clearValidate("destination");
                    // this.searchForm.stationId = v.id;
                    this.citySiteOffId = v.id;
                    this.form.destination = v;
                    if (v) {
                        this.form.destination = {
                            address: v.stationName,
                            city: v.cityName,
                            location: v.longitude + ',' + v.latitude,
                            title: v.stationName,
                            value: v.stationName,
                        }
                    }
                    break;
            }

        },  // 上车地点
        selectDestCityName(city) {
            this.form.productStartCityName = city;
            this.handTripDayChange(this.form.tripDay)
        },
        hideCitySel(b) {
            this.showHideCity = b
        },
        getInitOrderInfo(orderInfo) {
            this.form = {
                mechanism: null, // 下单机构
                resourceName: null,  // 资源
                productType: 1,  // 默认类型
                orderInst: null,  // 校验下单机构
                resourcesInst: null,  // 校验资源
                orderOrginfoId: '',  // 第三方订单号
                vehicleType: orderInfo.vehicleType,  // 车座车型
                playPersonNumber: 1,  // 出行人数
                tripDay: 1,  // 用车天数
                resourcePriceWay: 0,  // 乘车方式 0 专车 1 拼车
                departure: orderInfo.getOnPlace,  // 出发地点
                destination: orderInfo.getOffPlace, // 送达地点
                theCarDate: orderInfo.carDate,
                theCarTime: orderInfo.carTime,
                operatorValue: '',  // 运营商
                guiderName: '',  // 导游姓名
                orderAmount: keepTwoDecimal(orderInfo.orderAmount, 2),  // 订单金额
                operatorInfo: null,  // 当前选中运营商
                supplierValue: '',  // 供应商
                setAmount: keepTwoDecimal(orderInfo.setAmount, 2),  // 结算金额
                supplierInfo: null,  // 当前选中供应商,
                charteredCarDuration: undefined
            };
            if (orderInfo.getOnPlace.address) {
                let cityInfo = this.serverCityList.find(c => (orderInfo.getOnPlace.city == c.city)) || {};
                if (cityInfo.shortCode) this.getStation(cityInfo.shortCode, 1)
            }
            if (orderInfo.citySiteOnId) this.citySiteOnId = orderInfo.citySiteOnId;
            if (orderInfo.getOffPlace.address) {
                let cityInfo = this.serverCityList.find(c => (orderInfo.getOffPlace.city == c.city)) || {};
                if (cityInfo.shortCode) this.getStation(cityInfo.shortCode, 2)
            }
            if (this.citySiteOffId) this.citySiteOffId = orderInfo.citySiteOffId;

            this.theCarHour = orderInfo.carTime.substring(0, 2);
            this.theCarMinutes = orderInfo.carTime.substring(3);
        },
        getUserType() {
            this.operatorOptions = [{
                value: this.userInfo.refId,
                label: this.userInfo.companyName,
            }]; // 运营商select
            this.form.operatorInfo = {
                id: this.userInfo.refId,
                value: this.userInfo.refId,
                label: this.userInfo.companyName,
                operatorName: this.userInfo.companyName,
            };
            this.form.operatorValue = this.userInfo.refId;
            if (this.form.operatorInfo) this.getSupplierList();

        },  // 运营商
        handTripDayChange(days) {
            this.form.tripDay = days;
            if (this.form.theCarDate && days) {
                this.form.itineraryInfo = [];
                let infoBox = [];
                let dayCount = 0;
                dayCount = days;
                if (dayCount === 0.5) dayCount = 1;
                for (let i = 1; i <= dayCount; i++) {
                    let day = new Date(this.form.theCarDate).getTime() + (i - 1) * 8.64e7;
                    let info = {
                        travelDay: i,
                        handTripTimeHour: '09',
                        handTripTimeMinute: '00',
                        wayAddressList: [],
                        tripStartPlace: {
                            city: this.form.productStartCityName
                        },  // 行程 出发地址
                        tripEndPlace: {
                            city: this.form.productStartCityName
                        },  // 行程 送达地址
                        dateTime: this.filterDate(day, "MM月dd日"),
                        handTripTime: '09:00',
                        date: this.filterDate(day, "yyyy/MM/dd"),
                    };
                    infoBox.push(info);
                    this.form.itineraryInfo = infoBox;
                }
            } else {
                this.form.itineraryInfo = [];
            }

        },
        addWayAddress(index) {
            this.form.itineraryInfo[index].wayAddressList.push({
                id: +new Date(),
                tripWayPlace: {city: this.form.productStartCityName}
            });
        },
        deleteWayAddress(index, idxAddress) {
            this.form.itineraryInfo[index].wayAddressList.splice(idxAddress, 1);
        },
        handTripStartPlace(val, indexIdent) {
            if (indexIdent) this.form.itineraryInfo[indexIdent.index].tripStartPlace = val;
        },  // 行程信息 出发地址
        handWayAddressPlace(val, indexIdent) {
            if (indexIdent) this.form.itineraryInfo[indexIdent.index].wayAddressList[indexIdent.idxAddress].tripWayPlace = val;
        },  // 行程信息 途径地址
        handTripEndPlace(val, indexIdent) {
            if (indexIdent) this.form.itineraryInfo[indexIdent.index].tripEndPlace = val;
        },  // 行程信息 送达地址
        handTripTime(time, indexIdent) {  // 接 用车小时
            this.form.itineraryInfo[indexIdent.index].handTripTime = time;
            this.form.itineraryInfo[indexIdent.index].handTripTimeHour = time.substring(0, 2);
            this.form.itineraryInfo[indexIdent.index].handTripTimeMinute = time.substring(3);
        },
        async getSupplierId(supplierId) {
            let res = await this.http(`/galaxyOperator/getBySupplierId/${supplierId}`)
            if (res.success && res.data) {
                this.operatorOptions = [{
                    value: res.data.operatorId,
                    label: res.data.operatorName,
                }]; // 运营商select
                this.form.operatorInfo = {
                    id: res.data.operatorId,
                    value: res.data.operatorId,
                    label: res.data.operatorName,
                    operatorName: res.data.operatorName,
                };
                this.form.operatorValue = res.data.operatorId;
                if (this.form.operatorValue) this.operatorDisabled = true;
                this.getSupplierUserType();
            }
        },
        getSupplierUserType() {
            this.supplierOptions = [{
                value: this.userInfo.refId,
                label: this.userInfo.companyName,
            }]
            this.form.supplierInfo = {
                id: this.userInfo.refId,
                value: this.userInfo.refId,
                label: this.userInfo.companyName,
                supplierName: this.userInfo.companyName,
            }
            this.form.supplierValue = this.userInfo.refId;
            if (this.form.supplierValue) this.supplierDisabled = true;
        },
        createFilter(itemStr) {
            return (restaurant) => {
                return (restaurant.merchantName.indexOf(itemStr) >= 0);
            };
        },
        createProductFilter(itemStr) {
            return (restaurant) => {
                return (restaurant.resourceName.indexOf(itemStr) >= 0);
            };
        },
        clearAmountNum(val, name, index) {
            if (!index) return this.form[name] = val ? parseInt(val).toFixed(index) : 0;
            if (name.indexOf('.') !== -1) {
                const names = name.split('.');
                this.form[names[0]][names[1]] = keepTwoDecimal(val, index);
            } else
                this.form[name] = keepTwoDecimal(val, index);
            //结算金额根据订单金额变化
            if (name === 'orderAmount' && this.form.productType === 3) {
                this.form.setAmount = keepTwoDecimal(Number(this.form.orderAmount) * .8, 2)
                this.$forceUpdate()
            }
        },  // 金额小数点格式化
        handleDemType(type) {
            this.form.productType = type;
            this.form.playPersonNumber = 1;
            if (type == 1)
                this.form.resourcePriceWay = 0;
            if (type == 2)
                this.handTripDayChange(1);
            if (type == 1 || type == 2) {
                this.form.vehicleType = 1;
                this.form.resourceName = null;
                this.form.resourcesInst = null;
                this.form.supplierValue = '';
                this.form.supplierInfo = null;
                this.theCarDateTitle = '用车时间：'
            }
            if (type == 3) {
                this.maxPeopleNum = Infinity;
                this.theCarDateTitle = '出行日期：'
            }
            this.$refs["form"].clearValidate();
            this.operatorDisabled = false;
            this.supplierDisabled = false;

            if (this.userInfo.userType == '1') {
                this.getSupplierId(this.userInfo.refId)
            }
        },  // 下单类型
        handStartPlace(val) {
            this.form.departure = val;
            if (val.value)
                this.$refs["form"].clearValidate("departure");
            let cityInfo = this.serverCityList.find(c => (val.city == c.city)) || {};
            if (cityInfo.shortCode) this.getStation(cityInfo.shortCode, 1)
        },  // 出发地点
        handEndPlace(val) {
            this.form.destination = val;
            if (val.value)
                this.$refs["form"].clearValidate("destination");
            let cityInfo = this.serverCityList.find(c => (val.city == c.city)) || {};
            if (cityInfo.shortCode) this.getStation(cityInfo.shortCode, 2)
        },  // 送达地点
        handSelectDate(date) {
            this.form.theCarDate = date;
            if (this.form.productType === 3)
                this.getResourceAmountDay();
            if (this.form.productType === 2)
                this.handTripDayChange(this.form.tripDay)
        },
        // 日期
        handSelectTime(time) {
            this.form.theCarTime = time;
        },  // 时间
        handVehicle(type) {
            this.form.resourcePriceWay = type;
            this.form.playPersonNumber = 1;
            if (type == 0) {
                this.form.vehicleType = 1
                this.maxPeopleNum = 4
            }
            if (type == 1) {
                this.form.vehicleType = 14
                this.maxPeopleNum = Infinity;
            }
        },  // 乘车方式
        handSaddleTypeChange(value) {
            this.form.playPersonNumber = 1;
            this.maxPeopleNum = getSeatModel(parseInt(value)).seat;
            this.seatModelInfo = getSeatModel(parseInt(value));
        },  // 车座车型 change
        handPeopleNumChange(num) {
            this.form.playPersonNumber = num;
        },  // 出行人数
        async getMechanismList(mechanismStr, cb) {
            if (mechanismStr && mechanismStr.length > 1) {
                let form = {
                    merchantName: mechanismStr
                }
                let res = await this.http('/galaxyMerchant/merchantInfo', form);
                if (res.success && res.data) {
                    let mechanismList = res.data;
                    let mechanismListRes = mechanismStr ? mechanismList.filter(this.createFilter(mechanismStr)) : mechanismList;
                    cb(mechanismListRes);
                } else {
                    this.messageError('系统出错，请稍后!');
                }
            }
        },  // 下单机构
        handMechanismList(item) {
            this.form.orderInst = item;
            this.form.mechanism = '[' + item.orgId + ']' + ' ' + item.merchantName;
            this.$refs["form"].validateField("orderInst");
        },  // 校验下单机构
        async getProductResName(productStr, cb) {
            if (productStr && productStr.length > 1) {
                let form = {
                    resourceName: productStr
                }
                let res = await this.http('/galaxyResource/doProductResourceByResourceName', form, 'POST');
                if (res.success && res.data) {
                    let productList = res.data;
                    let productListRes = productStr ? productList.filter(this.createProductFilter(productStr)) : productList;
                    cb(productListRes);
                } else {
                    this.messageError('系统出错，请稍后!');
                }
            }
        },  // 资源
        handProductResList(item) {
            this.form.resourcesInst = item;
            this.form.resourceName = '[' + item.resourceNo + ']' + ' ' + item.resourceName;
            this.$refs["form"].validateField("resourcesInst");
            if (this.form.resourcesInst) {
                this.supplierOptions = [{
                    value: this.form.resourcesInst.supplierId,
                    label: this.form.resourcesInst.supplierName,
                }]  // 供应商select
                this.handOperatorChange();
                this.handSupplierChange();
                this.getResourceAmountDay();
            }
        },  // 校验资源
        clearResource() {
            this.form.resourceName = null;
            this.operatorDisabled = false;
            this.supplierDisabled = false;
            this.form.resourcesInst = undefined;
        },
        async getResourceAmountDay() {
            this.form.orderAmount = undefined;
            this.form.setAmount = undefined;
            if (!this.form.resourcesInst || !this.form.resourcesInst.resourceId || !this.form.theCarDate)
                return
            let form = {
                resourceId: this.form.resourcesInst.resourceId,
                inventoryDay: this.filterDate(this.form.theCarDate, 'yyyyMMdd'),
            }
            let res = await this.http('/galaxyInventory/getInventoryByResourceAndInventoryDay', form);
            if (res.success && res.data) {
                this.form.orderAmount = this.filterCurrency(res.data.peerPrice);
                this.form.setAmount = this.filterCurrency(res.data.settlePrice);
            }
        },  // 资源价格
        async getOperator() {
            let res = await this.http('/galaxyOperator/operatorInfoList', '', 'POST');
            if (res && res.success) {
                this.operatorOptions = res.data;
                this.operatorOptions.forEach(item => {
                    item.value = item.id;
                    item.label = item.operatorName;
                })
            }
        },  // 运营商列表
        handOperatorChange(item) {
            this.form.operatorInfo = this.operatorOptions.find(item1 => (item1.id == item));
            if (this.form.resourcesInst) {
                this.form.operatorInfo = {
                    id: this.form.resourcesInst.operatorId,
                    value: this.form.resourcesInst.operatorId,
                    label: this.form.resourcesInst.operatorName,
                    operatorName: this.form.resourcesInst.operatorName,
                }
                this.form.operatorValue = this.form.operatorInfo.id;
                if (this.form.operatorValue) this.operatorDisabled = true;
            }  // 当资源选中后 运营商

            if (this.form.operatorInfo) this.getSupplierList();
        },  // 运营商选中
        async getSupplierList() {
            let res = await this.http('/galaxySupplier/doGalaxyGetSupplierInfoByOperatorId', {operatorId: this.form.operatorInfo.id}, 'POST', false);
            if (res.success && res.data) {
                this.supplierOptions = res.data;
                this.supplierOptions.forEach(item => {
                    item.value = item.id;
                    item.label = item.supplierName
                })
            }
        },  // 供应商列表
        handSupplierChange(item) {
            this.form.supplierInfo = this.supplierOptions.find(item1 => (item1.id == item));
            if (this.form.resourcesInst) {
                this.form.supplierInfo = {
                    id: this.form.resourcesInst.supplierId,
                    value: this.form.resourcesInst.supplierId,
                    label: this.form.resourcesInst.supplierName,
                    supplierName: this.form.resourcesInst.supplierName,
                }
                this.form.supplierValue = this.form.supplierInfo.id;
                if (this.form.supplierValue) this.supplierDisabled = true;
            }  // 当资源选中后 供应商
            if (this.form.supplierInfo) this.form.setAmount = "0.00"
        },  // 供应商选中
        //下单
        async getDemandOrder() {  //
            this.subLoading = true
            this.loadingShow();
            let form = {};
            form.productType = this.form.productType;  // 产品类型
            form.orderOrginfoId = this.form.orderInst.id;  //下单机构id
            form.thirdOrderId = this.form.orderOrginfoId;  // 第三方id
            switch (this.form.orderInst.merchantPid) {
                case '0':
                    form.refType = '3';
                    break;
                case '1':
                    form.refType = '4';
                    break;
            }

            let tripDate = '', charteredCarType;
            switch (this.form.productType) {
                case 1:
                    form.resourcePriceWay = this.form.resourcePriceWay; // 乘车方式 计价方式
                    form.vehicleType = this.form.vehicleType;
                    form.departure = JSON.stringify(this.form.departure);   // 出发地
                    form.destination = JSON.stringify(this.form.destination);  // 目的地
                    tripDate = this.form.theCarDate + ' ' + this.form.theCarTime;
                    form.reserveTime = new Date(tripDate).getTime();
                    break;
                case 2:
                    form.charteredCarDuration = this.form.charteredCarDuration;
                    charteredCarType = (typeOfCharterCarOptions.find(({value}) => value === this.form.charteredCarDuration) || {})
                    form.expectedTime = charteredCarType.hour * this.form.tripDay * 60;
                    form.expectedDistanceLength = charteredCarType.distance * this.form.tripDay;
                    form.productName = charteredCarType.label;
                    form.tripDay = this.form.tripDay;  // 用车天数
                    form.vehicleType = this.form.vehicleType;
                    form.itineraryInfo = JSON.parse(JSON.stringify(this.form.itineraryInfo));  // 行程信息
                    form.itineraryInfo.forEach((item) => {
                        if (!item.tripStartPlace || !item.tripStartPlace.title) {
                            item.tripStartPlace = {
                                "title": "具体地址联系客人确认",
                                "location": "",
                                "point": {"lng": "", "lat": ""}
                            }
                        }
                        if (!item.tripEndPlace || !item.tripEndPlace.title) {
                            item.tripEndPlace = {
                                "title": "具体地址联系客人确认",
                                "location": "",
                                "point": {"lng": "", "lat": ""}
                            }
                        }
                    })
                    form.startCityName = this.form.productStartCityName;
                    if (form.itineraryInfo && form.itineraryInfo.length > 0) {
                        form.departure = JSON.stringify(form.itineraryInfo[0].tripStartPlace);  // 出发地址
                        let lastDestination = form.itineraryInfo[form.itineraryInfo.length - 1];
                        form.destination = JSON.stringify(lastDestination.tripEndPlace);  // 送达地址
                        let tripDate = this.filterDate(new Date(`${form.itineraryInfo[0].date}` + ' ' + `${form.itineraryInfo[0].handTripTime}`), "yyyy/MM/dd hh:mm:ss");
                        form.reserveTime = +new Date(tripDate);  // 日期时间
                    }
                    form.itineraryInfo = JSON.stringify(form.itineraryInfo);
                    break;
                case 3:
                    //资源存在
                    if (this.form.resourceExist === '是') {
                        form.productId = this.form.resourcesInst.productId;  // 产品id ,
                        form.resourceId = this.form.resourcesInst.resourceId;  // 资源Id ,
                    } else {
                        form.productName = this.form.productName;
                        form.productFrom = 18;
                        form.resourceUseCar = this.useCar ? 1 : 0;
                    }
                    form.reserveTime = +new Date(this.form.theCarDate);
                    form.guiderName = this.form.guiderName;  // 导游姓名
                    form.receivableAmount = this.form.receivableAmount;//应收金额
                    //如果有司机名称 那就提交司机信息
                    if (form.guiderName || this.form.distributionGuiderDto.guiderName) {
                        form.distributionGuiderDto = {
                            ...this.form.distributionGuiderDto,
                            carBrandModel: (this.form.distributionGuiderDto.carBrandModel || []).join('/'),
                            touristGuideName: form.guiderName,
                            driverPrice: this.form.distributionGuiderDto.driverPrice || 0
                        };
                    }
                    //如果是用车那就把用车信息放进去
                    if (this.useCar) {
                        form.tripDay = this.form.tripDay;  // 用车天数
                        tripDate = dayjs(`${dayjs(this.form.theCarDate).format('YYYY/MM/DD')} ${(this.form.theCarTime || '08:00')}`, 'YYYY/MM/DD HH:mm');
                        form.reserveTime = tripDate.valueOf();
                        form.vehicleType = this.form.vehicleType;
                        form.departure = JSON.stringify(this.form.departure);   // 出发地
                        form.destination = JSON.stringify(this.form.destination);  // 目的地
                    }
                    break;
                default:
                    form.reserveTime = new Date(this.form.theCarDate).getTime();
            }
            // 出行时间
            form.playPersonNumber = this.form.playPersonNumber;  // 出行人数
            form.tripUserName = this.form.tripUserName;  // 出行人姓名
            form.tripUserMobile = this.form.tripUserMobile;  // 出行人手机号
            form.orderRemake = this.form.orderRemake;  // 订单备注
            form.productOperatorId = this.form.operatorValue;  // 运营商id ,
            if (this.form.supplierValue) form.resourceSupplierId = this.form.supplierValue;  // 供应商id ,
            form.orderAmount = this.form.orderAmount; // 订单金额
            form.settleAmount = this.form.setAmount || '0.00';  // 结算金额
            try {
                let res = await this.http('/galaxyOrder/createBackendOrder', form, 'POST');
                if (res.success && res.data) {
                    this.$message({
                        message: '需求下单预订成功',
                        type: 'success'
                    });
                    this.demandInfoShow = false;
                    this.sessionStorage.set("orderListRefresh", true)
                    this.$router.push({
                        name: this.form.productType == 3 ? 'tripList' : 'userCarList'
                    })
                    this.init();
                } else {
                    this.messageError('系统出错，请稍后!');
                }
            } catch (error) {
                this.messageError()
            }
            this.loadingHide();
            this.subLoading = false

        },
        cancelDemandOrder() {
            this.demandInfoShow = false;
        },  // 取消
        handBookBtn() {
            if (this.form.productType == 2 && this.form.itineraryInfo && this.form.itineraryInfo.length > 0) {
                this.form.departure = this.form.itineraryInfo[0].tripStartPlace;  // 出发地址
                let lastDestination = this.form.itineraryInfo[this.form.itineraryInfo.length - 1];
                this.form.destination = lastDestination.tripEndPlace;  // 送达地址
                let tripDate = this.filterDate(new Date(`${this.form.itineraryInfo[0].date}` + ' ' + `${this.form.itineraryInfo[0].handTripTime}`), "yyyy/MM/dd hh:mm:ss");
                this.form.reserveTime = +new Date(tripDate);  // 日期时间
                this.form.theCarDate = this.form.itineraryInfo[0].date;
                this.form.theCarTime = this.form.itineraryInfo[0].handTripTime;
            }
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.seatModelInfo = getSeatModel(parseInt(this.form.vehicleType));
                    this.demandInfoShow = true;
                } else {
                    return false;
                }
            });
        },
        //通过司机姓名模糊查询司机
        queryDriverByName(driverName, cb) {
            if (!driverName)
                return cb([])
            this.queryDriver({driverName}, cb)
        },
        //通过司机手机号模糊查询司机
        queryDriverByMobile(driverMobile, cb) {
            if (!isCellphone(driverMobile))
                return cb([])
            this.queryDriver({driverMobile}, cb)
        },
        //模糊查询司机
        async queryDriver(form, cb) {
            let list = []
            try {
                let res = await this.http('/galaxyOrder/getDriverListBySupplier', form, 'POST');
                if (res.success && res.data) {
                    list = res.data.map((item) => ({
                        ...item,
                        value: `${item.driverName} ${item.driverMobile}`
                    }))
                }
            } finally {
                cb(list)
            }
        },
        //模糊下拉项被选择
        handleSelectDriver(opt) {
            this.selectDriverInfo = {
                ...this.form.distributionGuiderDto,
                ...opt,
                guiderName: opt.driverName,
                guiderMobile: opt.driverMobile,
                carNumber: opt.carNumberPlate,
                carNum: opt.carNumberPlate.slice(1, opt.carNumberPlate.length - 1),
                selectShortCity: opt.carNumberPlate.slice(0, 1),
                driverId: opt.id,
                carType: Number(opt.carType),
                supplierId: opt.driverSupplierId,
                carBrandModel: [opt.carBrand, opt.carModel]
            };
            this.form.distributionGuiderDto = {
                ...this.selectDriverInfo
            }
        },
        //输入手机号丢失焦点 判断司机姓名和手机号是否一致 不一致清除id
        handleClearDriverId(name) {
            if (this.selectDriverInfo && this.form.distributionGuiderDto[name] !== this.selectDriverInfo[name]) {
                this.form.distributionGuiderDto.driverId = null;
                this.form.distributionGuiderDto.id = null;
                this.selectDriverInfo = null;
            }
        }
    },
    //监听模版变量
    watch: {
        'form.productType': function (n) {  // 监听类型
            if (n == 1 || n == 2) {
                this.form.theCarDate = new Date(+new Date() + this.oneDayTime);
                if (n === 1)
                    this.form.theCarDate = this.filterDate(this.form.theCarDate, 'yyyy/MM/dd')
                if (!this.form.theCarTime)
                    this.form.theCarTime = '08:00';
            }
            if (n == 3) {
                this.form.theCarDate = new Date();
                this.form.theCarTime = ''
            }
            this.handTripDayChange(this.form.tripDay)
        },
        'form.mechanism': function (n) {
            const orderInst = this.form.orderInst || {};
            if (`[${orderInst.orgId}] ${orderInst.merchantName}` != n)
                this.form.orderInst = null;
        },  // 下单机构
        'form.resourceName': function (n) {
            const resourcesInst = this.form.resourcesInst || {};
            if (`[${resourcesInst.resourceNo}] ${resourcesInst.resourceName}` != n)
                this.form.resourcesInst = null;
        },  // 资源
        //监听车牌两个字段 组合车牌
        "form.distributionGuiderDto.carNum": function (val) {
            this.form.distributionGuiderDto.carNumber = `${this.form.distributionGuiderDto.selectShortCity}${val}`
        },
        "form.distributionGuiderDto.selectShortCity": function (val) {
            this.form.distributionGuiderDto.carNumber = `${val}${this.form.distributionGuiderDto.carNum}`
        }
    }

}
</script>

